import React, { useState, useEffect, useRef, setState } from 'react';
import { useAuth } from "../../hooks/AuthProvider";
import { getHeaders } from '../../utils/apis';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';


const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--10': {
        backgroundColor: getBackgroundColor(theme.palette.warning.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--20': {
        backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),

        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--30': {
        backgroundColor: getBackgroundColor(
            theme.palette.secondary.main,
            theme.palette.mode,
        ),
        color: "red",
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.secondary.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.secondary.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--40': {
        // backgroundColor: getBackgroundColor(
        //     theme.palette.warning.main,
        //     theme.palette.mode,
        // ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--50': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));

const fields_mau = [
    {
        // Visible in table header and when matching columns.
        label: "Name",
        // This is the key used for this field when we call onSubmit.
        key: "name",
        // Allows for better automatic column matching. Optional.
        alternateMatches: ["first name", "first"],
        // Used when editing and validating information.
        fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
        },
        // Used in the first step to provide an example of what data is expected in this field. Optional.
        example: "Stephanie",
        // Can have multiple validations that are visible in Validation Step table.
        validations: [
            {
                // Can be "required" / "unique" / "regex"
                rule: "required",
                errorMessage: "Name is required",
                // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                level: "error",
            },
        ],
    },
];
const fields = [
    {
        label: "Order Id",
        key: "order_id",
        alternateMatches: ["orderid"],
        fieldType: { type: "input" },
        example: "YB101",
        validations: [{ rule: "required", errorMessage: "Order ID is required", level: "error", }],
    },
    {
        label: "Fabric Code",
        key: "fabric_code",
        alternateMatches: ["fabriccode"],
        fieldType: { type: "input" },
        example: "PF1",
        validations: [{ rule: "required", errorMessage: "Fabric Code is required", level: "error", }],
    },
    {
        label: "Collar",
        key: "collar",
        alternateMatches: ["collar"],
        fieldType: { type: "input" },
        example: "40",
        validations: [{ rule: "required", errorMessage: "Collar is required", level: "error", }],
    },
    {
        label: "Chest",
        key: "chest",
        alternateMatches: ["chest"],
        fieldType: { type: "input" },
        example: "140",
        validations: [{ rule: "required", errorMessage: "Chest is required", level: "error", }],
    },
    {
        label: "Waist",
        key: "waist",
        alternateMatches: ["waist"],
        fieldType: { type: "input" },
        example: "122",
        validations: [{ rule: "required", errorMessage: "Waist is required", level: "error", }],
    },
    {
        label: "Hip",
        key: "hip",
        alternateMatches: ["hip"],
        fieldType: { type: "input" },
        example: "136",
        validations: [{ rule: "required", errorMessage: "Hip is required", level: "error", }],
    },
    {
        label: "Shoulders",
        key: "shoulders",
        alternateMatches: ["shoulders"],
        fieldType: { type: "input" },
        example: "56",
        validations: [{ rule: "required", errorMessage: "Shoulders is required", level: "error", }],
    },
    {
        label: "Sleeve Length",
        key: "sleeve_length",
        alternateMatches: ["sleeve"],
        fieldType: { type: "input" },
        example: "72",
        validations: [{ rule: "required", errorMessage: "Sleeve length is required", level: "error", }],
    },
    {
        label: "Upper Arm",
        key: "upper_arm",
        alternateMatches: ["upper_arm"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Upper Arm is required", level: "error", }],
    },
    {
        label: "Wrist",
        key: "wrist",
        alternateMatches: ["wrist"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Wrist is required", level: "error", }],
    },
    {
        label: "Back length casual",
        key: "back_length_casual",
        alternateMatches: ["back_length_casual"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Back length casual is required", level: "error", }],
    },
    {
        label: "Back length business",
        key: "back_length_business",
        alternateMatches: ["back_length_business"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Back length business is required", level: "error", }],
    },
    {
        label: "Back length type",
        key: "back",
        alternateMatches: ["back"],
        fieldType: { type: "input" },
        example: "90",
        validations: [{ rule: "required", errorMessage: "Back length type is required", level: "error", }],
    },


]
/*
order_id
fabric_code
collar
chest
waist
hip
shoulders
sleeve_length
upper_arm
wrist
back_length_casual
back_length_business
*/


const columns = [
    { field: 'order_id', headerName: 'Order ID', width: 90 },
    { field: 'pattern', headerName: 'pattern', width: 90 },
    {
        field: 'fabric_code',
        headerName: 'Fabric code',
        width: 150,
    },
    {
        field: 'collar',
        headerName: 'Collar',
        width: 150,
    },
    {
        field: 'chest',
        headerName: 'Chest',
        width: 150,
    },
    {

        field: 'waist',
        headerName: 'Waist',
        width: 100,
    }, {
        field: 'hip',
        headerName: 'Hip',
        width: 100,
    }, {
        field: 'shoulders',
        headerName: 'Shoulders',
        width: 100,
    }, {
        field: 'sleeve_length',
        headerName: 'Sleeve Length',
        width: 100,
    }, {
        field: 'upper_arm',
        headerName: 'Upper Arm',
        width: 100,
    }, {
        field: 'wrist',
        headerName: 'Wrist',
        width: 100,
    },
    {
        field: 'back_length',
        headerName: 'Back Length',
        width: 120,
    },
    {
        field: 'back_length_casual',
        headerName: 'Back Length Casual',
        width: 180,
    },
    {
        field: 'back_length_business',
        headerName: 'Back Length Business',
        width: 180,
    },

    {
        field: 'back',
        headerName: 'Back Length Type',
        width: 180,
    },

];

export default function PatternSearch(props) {
    let [isOpen, setIsopen] = useState(true);
    let [validData, setData] = useState([]);

    let [returnData, setReturnData] = useState([]);
    let [isWait, setWait] = useState(true);

    const auth = useAuth();

    if (auth.user == null) {
        auth.logOut();
        // return;
    }

    // Determines if modal is visible.


    // Called when flow is closed without reaching submit.
    const onClose = () => {
        setIsopen(false);
    };

    // Called after user completes the flow. Provides data array, where data keys matches your field keys. 
    const onSubmit = async (data, file) => {
        if (auth.user == null) {
            auth.logOut();
            // return;
        }

        setIsopen(false);
        setData(data.validData);
        console.log(data);
        data.validData.forEach((row, idx) => {
            row['id'] = idx;
            row['pattern'] = 'Wait...';
        });
        setReturnData(data.validData);

        fetch("/api/searchpatternbatch/", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify({
                data: data.validData
            }),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((rowData) => {
                if (rowData["result"]) { auth.logOut(); return; }

                setReturnData(rowData);
                setWait(false);
            })
            .catch((error) => console.log(error));
        //fetchData();


    }

    const handleSave2Excel = () => {
        //   openModalExportPB();
        saveAsExcel('Pattern_Search_');
        //  alert("OK");
    }

    const xls_border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    };

    const xls_alignment = { vertical: 'top', horizontal: 'left' };

    async function saveAsExcel(prefix) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Shirt.vn';
        workbook.lastModifiedBy = 'Admin';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        workbook.calcProperties.fullCalcOnLoad = true;
        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]

        let timeSpan = new Date().toISOString();

        // Results sheet
        let sheet = workbook.addWorksheet('Results', { views: [{ showGridLines: true }] });

        // let customCell = sheet.getCell("A1");
        // customCell.font = {
        //     name: "Arial",
        //     family: 4,
        //     size: 12,
        //     underline: false,
        //     bold: true
        // };
        // customCell.value = " Ngày " + timeSpan;

        // sheet.addRow();


        let font = {
            name: "Arial",
            family: 4,
            size: 14,
            underline: false,
            bold: false
        };


        for (let i = 0; i < 12; i++) {
            //sheet.getRow(2).getCell(i + 1).value = '';
            sheet.getColumn(i + 1).width = 12;
        }

        returnData.forEach((row, idx) => {
            let r = sheet.addRow();
            r.getCell(1).value = row.order_id;
            r.getCell(2).value = row.fabric_code;
            r.getCell(3).value = row.pattern;
            r.getCell(4).value = row.collar;
            r.getCell(5).value = row.chest;
            r.getCell(6).value = row.waist;
            r.getCell(7).value = row.hip;
            r.getCell(8).value = row.shoulder;
            r.getCell(9).value = row.sleeve_length;
            r.getCell(10).value = row.upper_arm;
            r.getCell(11).value = row.wrist;
            r.getCell(12).value = row.back_length;

            for (let i = 1; i <= 12; i++) {
                r.getCell(i).border = xls_border;
                r.getCell(i).font = font;
            }



        });



        // Save file

        let fileName = `${prefix}-${timeSpan}`;

        workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                `${fileName}.xlsx`
            );
        });

    }

    // Theo chieu doc 6*N
    async function saveAsExcel_6xN(prefix) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Shirt.vn';
        workbook.lastModifiedBy = 'Admin';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.lastPrinted = new Date();
        workbook.calcProperties.fullCalcOnLoad = true;
        workbook.views = [
            {
                x: 0, y: 0, width: 10000, height: 20000,
                firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ]

        let timeSpan = new Date().toISOString();

        // Results sheet
        let sheet = workbook.addWorksheet('Results', { views: [{ showGridLines: true }] });

        let customCell = sheet.getCell("A1");
        customCell.font = {
            name: "Arial",
            family: 4,
            size: 14,
            underline: false,
            bold: true
        };
        customCell.value = " Ngày " + timeSpan;

        sheet.addRow();


        let font = {
            name: "Arial",
            family: 4,
            size: 14,
            underline: false,
            bold: false
        };

        let bigrow = 0;
        let count = 0;
        let r_order_id = null;
        let r_fabric = null;
        let r_pattern = null;
        let r_collar = null;
        let r_chest = null;
        let r_waist = null;
        let r_hip = null;
        let r_shoulder = null;
        let r_arm_length = null;
        let r_upper_arm = null;
        let r_wrist = null;
        let r_back_length = null;

        for (let i = 0; i < 6; i++) {
            //sheet.getRow(2).getCell(i + 1).value = '';
            sheet.getColumn(i + 1).width = 12;
        }

        returnData.forEach((row, idx) => {
            if (count == 0) {
                sheet.addRow();
                sheet.addRow();
                r_order_id = sheet.addRow();
                r_fabric = sheet.addRow();
                r_pattern = sheet.addRow();
                r_collar = sheet.addRow();
                r_chest = sheet.addRow();
                r_waist = sheet.addRow();
                r_hip = sheet.addRow();
                r_shoulder = sheet.addRow();
                r_arm_length = sheet.addRow();
                r_upper_arm = sheet.addRow();
                r_wrist = sheet.addRow();
                r_back_length = sheet.addRow();
            }


            r_order_id.getCell(count + 1).value = row.order_id;
            r_fabric.getCell(count + 1).value = row.fabric_code;
            r_pattern.getCell(count + 1).value = row.pattern;
            r_collar.getCell(count + 1).value = row.collar;
            r_chest.getCell(count + 1).value = row.chest;
            r_waist.getCell(count + 1).value = row.waist;
            r_hip.getCell(count + 1).value = row.hip;
            r_shoulder.getCell(count + 1).value = row.shoulder;
            r_arm_length.getCell(count + 1).value = row.sleeve_length;
            r_upper_arm.getCell(count + 1).value = row.upper_arm;
            r_wrist.getCell(count + 1).value = row.wrist;
            r_back_length.getCell(count + 1).value = row.back_length;

            for (let i = 3; i <= 14; i++) {
                sheet.getRow(bigrow * 14 + i + 2).getCell(count + 1).border = xls_border;
                sheet.getRow(bigrow * 14 + i + 2).getCell(count + 1).font = font;
            }

            count++;
            if (count >= 6) {
                count = 0;
                bigrow++;
            }

        });

        // let header = sheet.addRow();
        // header.font = {
        //     name: "Arial",
        //     family: 4,
        //     size: 11,
        //     underline: false,
        //     bold: false
        // };

        // header.getCell(1).value = "OrderID";
        // sheet.getColumn(1).width = 12;
        // header.getCell(1).border = xls_border;

        // let r_fabric = sheet.addRow();
        // let r_pattern = sheet.addRow();
        // let r_collar = sheet.addRow();
        // let r_chest = sheet.addRow();
        // let r_waist = sheet.addRow();
        // let r_hip = sheet.addRow();
        // let r_shoulder = sheet.addRow();
        // let r_arm_length = sheet.addRow();
        // let r_upper_arm = sheet.addRow();
        // let r_wrist = sheet.addRow();
        // let r_back_length = sheet.addRow();

        // sheet.getRow(4).getCell(1).value = "Fabric";
        // sheet.getRow(5).getCell(1).value = "pattern";
        // sheet.getRow(6).getCell(1).value = "collar";
        // sheet.getRow(7).getCell(1).value = "chest";
        // sheet.getRow(8).getCell(1).value = "Waist";
        // sheet.getRow(9).getCell(1).value = "hips";
        // sheet.getRow(10).getCell(1).value = "shoulder";
        // sheet.getRow(11).getCell(1).value = "arm_length";
        // sheet.getRow(12).getCell(1).value = "upper_arm";
        // sheet.getRow(13).getCell(1).value = "wrist";
        // sheet.getRow(14).getCell(1).value = "backlength";


        // let font = {
        //     name: "Arial",
        //     family: 4,
        //     size: 12,
        //     underline: false,
        //     bold: false
        // };

        // for (let i = 4; i <= 14; i++) {
        //     sheet.getRow(i).getCell(1).border = xls_border;
        //     sheet.getRow(i).font = font;
        // }


        // returnData.forEach((row, idx) => {
        //     sheet.getColumn(idx + 2).width = 12;
        //     header.getCell(idx + 2).value = row.order_id;
        //     r_fabric.getCell(idx + 2).value = row.fabric_code;
        //     r_pattern.getCell(idx + 2).value = row.pattern;
        //     r_collar.getCell(idx + 2).value = row.collar;
        //     r_chest.getCell(idx + 2).value = row.chest;
        //     r_waist.getCell(idx + 2).value = row.waist;
        //     r_hip.getCell(idx + 2).value = row.hip;
        //     r_shoulder.getCell(idx + 2).value = row.shoulder;
        //     r_arm_length.getCell(idx + 2).value = row.sleeve_length;
        //     r_upper_arm.getCell(idx + 2).value = row.upper_arm;
        //     r_wrist.getCell(idx + 2).value = row.wrist;
        //     r_back_length.getCell(idx + 2).value = row.back_length;

        //     for (let i = 4; i <= 14; i++) {
        //         sheet.getRow(i).getCell(idx + 2).border = xls_border;
        //     }

        // });

        // data[idx]['pattern'] = result[0].pattern;
        // data[idx]['chest'] = result[0].chest;
        // data[idx]['shoulder'] = result[0].shoulder;
        // data[idx]['upper_arm'] = result[0].upper_arm;
        // data[idx]['collar'] = result[0].collar;
        // data[idx]['sleeve_length'] = result[0].sleeve;
        // data[idx]['back_length'] = result[0].back_length;
        // data[idx]['waist'] = result[0].waist;
        // data[idx]['wrist'] = result[0].wrist;
        // data[idx]['hip'] = result[0].hip;

        // await fetch("/api/order_sum_pb/" + tailorshop.tailorshop_id + "/" + pb.pb_code, {
        //     method: "GET",
        //     headers: getHeaders(auth),
        // })
        //     .then((response) => response.json())
        //     //.then((jsonData) => addId(jsonData, "client_id"))
        //     .then((rowData) => {
        //         if (rowData["result"]) { auth.logOut(); return; }

        //         console.log(rowData);

        //         let count = 0;

        //         rowData.forEach(rec => {
        //             let row = sheet.addRow();

        //             row.getCell(1).value = rec['order_prefix'] + rec['order_id'];
        //             row.getCell(2).value = rec['quantity'];
        //             // row.getCell(3).value = rec['notes'];
        //             row.getCell(4).value = rec['fabric_code'];

        //             count = count + rec['quantity'];
        //             //Style

        //             for (let j = 1; j <= 4; j++) {
        //                 row.getCell(j).border = xls_border;
        //                 row.getCell(j).alignment = xls_alignment;
        //             }
        //             row.getCell(2).alignment = { vertical: 'top', horizontal: 'right' };
        //         });

        //         let row = sheet.addRow();
        //         row.getCell(2).value = count;

        //     })
        //     .catch((error) => console.log(error));



        // Save file

        let fileName = `${prefix}-${timeSpan}`;

        workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                `${fileName}.xlsx`
            );
        });

    }


    return (<>
        <h1>Pattern Search</h1>
        <ReactSpreadsheetImport isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />
        Import Data:
        {validData.length} records

        {validData.length >= 1 && <>
            {isWait ?
                <><br /><span style={{ color: "blue", fontWeight: "bold" }}>Please wait...</span></>
                :
                <><br /><span style={{ color: "red", fontWeight: "bold" }}>FINISHED</span> &nbsp;
                    <button type="submit" className="btn btn-primary mx-2" style={{ height: "50px" }}
                        onClick={handleSave2Excel}>Save to Excel file</button>
                    <br />
                </>
            }
        </>
        }




        <StyledDataGrid
            rows={returnData}
            columns={columns}
            checkboxSelection={true}
            disableMultipleRowSelection={true}
            // columnGroupingModel={columnGroupingModel}
            density='compact'
            //  onRowClick={handleRowClick}
            // onRowSelectionModelChange={handleSelectionChange}

            pageSizeOptions={[5, 10, 25, 50, 100]}
            initialState={{
                pagination: {
                    paginationModel: { pageSize: 25, page: 0 },
                },
            }}
            getRowClassName={(params) => `super-app-theme--${params.row.state}`}
        />

    </>);
}